import { CContainer, CCard, CCardBody, CModalHeader, CModalTitle, CModalBody, CModalFooter, CButton, CModal, CSpinner } from "@coreui/react";
import { useGLNContext } from "../../context/glnContext";
import { CSmartTable } from "@coreui/react-pro";
import { ImBarcode, ImDownload, ImFilePdf, ImPodcast } from "react-icons/im";
import { generateGLNDocument } from "../../services/generalService";
import { toast } from "react-toastify";
import { useState } from "react";
const ViewGLNs = () => {
  const {
    glns,
    dispatch,
    previousPage,
    glnType,
    level,
    selectedgln,
    affiliation,
    affiliationTo,
    isOnwedBy,
    openningDate,
    latitude,
    longitude,
    functionName,
    functionDescription,
    AddressName,
    IPAddress,
    PortNumber,
    URL,
    PhoneNumber,
    Email,
    Street,
    City,
    State,
    LandMark,
    NearestBusStop,
    PostalCode,
  } = useGLNContext();
  const columns = [
    {
      key: "action",
      // isImg: true,
      label: "Download",
      filter: false,
      sorter: false,
    },
    {
      key: "companyName",
      // isImg: true,
      label: "Company Name",
      filter: false,
      sorter: true,
    },
    {
      key: "email",
      label: "Email",
      filter: true,
    },
    {
      key: "glnType",
      label: "GLN TYPE",
      filter: true,
    },

    {
      key: "isApproved",
      label: "Status",
      filter: false,
    },
    {
      key: "gln",
      label: "GLN",
      filter: false,
    },
    {
      key: "street",
      label: "Address",
      filter: false,
      sorter: false,
    },
  ];
  const [loading, setLoading] = useState(false);
  const [showmodal, setShowmodal] = useState(false);
  const [docLink, setDocLink] = useState("");
  const [selctedgln, setSelctedgln] = useState("");


  const handleGLNDownload = async () => {
    fetch(docLink).then((response) => {
      response.blob().then((blob) => {

        // Creating new object of PDF file
        const fileURL =
          window.URL.createObjectURL(blob);

        // Setting various property values
        let alink = document.createElement("a");
        alink.href = fileURL;
        alink.download = selctedgln + ".pdf";
        alink.click();
      });
    });
  }

  const getGLNDocument = async (gln) => {
    setSelctedgln(gln);
    setShowmodal(true)
    setLoading(true)

    try {
      const data = (await generateGLNDocument(gln)).data;
      // console.log("doc result", data);
      if (data["status"] == 1) {
        setDocLink(data["returnedObject"]);
      } else {
        toast.info("unable to generate document");
      }
      // toast.info(data.message);
      // if (data.isSuccess == true) {
      //   dispatch({
      //     type: "BARCODE_IMAGE",
      //     payload: {
      //       mainImageLink: data.returnedObject?.mainImageLink,
      //       displayImageLink: data.returnedObject?.displayImageLink,
      //       gtin: gtin,
      //     },
      //   });
      // }
    } catch (er) {
      setLoading(false)
    }
  };

  return (
    <>

      <CModal visible={showmodal} onClose={() => setShowmodal(false)}>
        <CModalHeader onClose={() => setShowmodal(false)}>
          <CModalTitle className="d-flex justify-content-between"><span>GLN Document Download</span></CModalTitle>
        </CModalHeader>
        <CModalBody className="d-flex justify-content-center">
          {docLink != "" ?
            <div className="d-flex justify-content-center flex-column">
              <div className="d-flex justify-content-center">
              <ImFilePdf
                
                size={100}

              />
              </div>
              
              <h5 className="mt-4 text-decoration-underline g1-gold fw-bold">PLEASE NOTE!</h5>
              <p>The content of this document is based entirely on inputs from the subscriber. GS1 Nigeria disclaims any responsibility or liability for any inaccuracies or omissions in the content. If there is a need to update any information contained in this document, kindly contact GS1 Nigeria. This document is not intended for use in any legal proceedings.</p>
            </div>


            : <CSpinner component="span" style={{ width: 5 + 'rem', height: 5 + 'rem' }} aria-hidden="true" />}

        </CModalBody>
        <CModalFooter className="d-flex justify-content-between">
          <div>
            <span>{selctedgln}</span>
          </div>
          <div>
            <CButton className="mx-2" color="secondary" onClick={() => setShowmodal(false)}>
              Close
            </CButton>
            {/* <a href=`${downloadBarcode}` download></a> */}
            <CButton color="primary" onClick={() => handleGLNDownload()}>
              Get Document
            </CButton>
          </div>
        </CModalFooter>
      </CModal>
      <div className="border-t-2 mx-3 my-3 border-b-gs1-blue">
        <CCard>
          <CCardBody className="create_gtn-card">
            <div style={{ overflow: "scroll" }}>
              <CSmartTable
                activePage={1}
                cleaner
                clickableRows
                columns={columns}
                columnFilter
                columnSorter
                items={glns}
                itemsPerPageSelect
                itemsPerPage={5}
                // pagination
                scopedColumns={{
                  street: (glns) => (
                    <td>
                      {" "}
                      {glns.street +
                        "," +
                        glns.city +
                        "," +
                        glns.state +
                        " " +
                        "Nigeria"}{" "}
                    </td>
                  ),
                  action: (glns) => (
                    <td className="cursor-pointer">
                      {glns.gln != null && glns.gln.length == 13 ?
                        <ImDownload

                          size={20}
                          onClick={() => getGLNDocument(glns.gln)}
                        /> : <span></span>
                      }
                    </td>
                  ),
                  // brickCategory: (products) => (
                  //   <td>{products.brickCategory.brick}</td>
                  // ),
                }}
                //       dateoflisting: (products) =>
                //         formatTime(products.dateoflisting),
                //       iscompleted: (products) => (
                //         <td>{fetchFaceEmoji(products.iscompleted)}</td>
                //       ),
                //       generate_barcode: (products) => (
                //         <td>
                //           {
                //             <ImBarcode
                //               size={20}
                //               onClick={
                //                 () => getImages(products.gtin)
                //                 // dispatch({
                //                 //   type: "OPEN_GEN",
                //                 //   payload: products.gtin,
                //                 // })
                //               }
                //             />
                //           }
                //         </td>
                //       ),
                //       edit_products: (products) => (
                //         <td>
                //           {
                //             <BiEdit
                //               size={20}
                //               onClick={() => callUpdate(products)}
                //             />
                //           }
                //         </td>
                //       ),
                //     }}
                //     sorterValue={{ column: "name", state: "asc" }}
                tableFilter
                tableHeadProps={{
                  color: "",
                }}
                tableProps={{
                  striped: true,
                  bordered: false,
                  hover: true,
                }}
              />
            </div>
          </CCardBody>{" "}
        </CCard>{" "}
      </div>{" "}
    </>
  );
};

export default ViewGLNs;
